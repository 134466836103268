<script setup>
import {onMounted} from 'vue';
import {Head, router} from '@inertiajs/vue3';
import Banner from '@/Components/Banner.vue';
import Navbar from '@/Components/Customer/Navbar.vue';
import {useColorMode} from '@vueuse/core'

defineProps({
    title: String,
});

let colourMode = useColorMode()

onMounted(() => {
    document.querySelector('html').setAttribute('data-theme', colourMode.value === 'dark' ? 'halloween' : 'bumblebee')
})

const logout = () => {
    router.post(route('logout'));
};
</script>

<template>
    <div>
        <Head :title="title"/>

        <Banner/>

        <div class="min-h-screen bg-gray-100 dark:bg-gray-900">
            <Navbar/>

            <!-- Page Heading -->
            <header v-if="$slots.header" class="bg-white dark:bg-gray-800 shadow">
                <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <slot name="header"/>
                </div>
            </header>

            <slot name="header"></slot>

            <!-- Page Content -->
            <main>
                <slot/>
            </main>
        </div>
    </div>
</template>
